@import "core/bourbon";
@import "normalize";
@import "base/base";

$bourbon: (
    "modular-scale-base": 1rem,
    "modular-scale-ratio": $major-second,
);

@keyframes fadeIn {
    from {
        opacity: 0;
    }
  
    to {
        opacity: 1;
    }
} 

$site-color:#00B900;

a {
    text-decoration: none;
}

.container {
    max-width: 1000px;
    @include margin(null auto);
    @include padding(null .75rem);
}

.row {
    display: flex;
    flex-wrap: wrap;
}

.hero-image {
    max-width: 754px;
    @include margin(null auto);
    @include padding(15rem .75rem);

    opacity: 0;
    &.on {
        animation: fadeIn 2s;
        opacity: 1;
    }
}
.frontpage-nav {
    font-size: modular-scale(4);
    line-height: 1.8;
    background: url("../img/fp-nav-bg.jpg") no-repeat 110% top / auto 110%;
    @include padding(9vmin null);
}
.global-footer {
    background: #EAEAEA;
    .row {
        align-items: center;
        @media screen and (min-width: 800px) {
            flex-wrap: nowrap;
            > * + * {
                border-left: 3px solid $site-color;
            }
        }
    }
}
.footer-logo {
    @include padding(3rem 6vmin);
    max-width: 396px;
}
.footer-content {
    @include padding(3rem 6vmin);
}
.footer-nav {
    ul {
        display: flex;
        flex-wrap: wrap;
        @include margin(null -.375rem);
        line-height: 1.25;
        > li a {
            @include padding(.375rem);
            border-radius: 14px;
            &:hover {
                background: $site-color;
                color: #fff;
            }
        }
    }
}
.footer-info {
    font-size: modular-scale(1);
    margin-top: 1.5rem;
    line-height: 1.25;
    .large {
        font-size: calc(16px + 1.6vw);
    }
}

.right {
    font-size: calc(10px + .4vw);
    margin-top: 1.5rem;
}

.global-header {
    
}
.page-logo {
    max-width: 380px;
    @include margin(null auto);
    @include padding(2rem .75rem);
}
.page-nav {
    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        > li a {
            @include padding(1rem 2rem);
            border-radius: 24px;
            &:hover {
                background: $site-color;
                color: #fff;
            }
        }
    }
}
.page-title {
    h1 {
        font-weight: 700;
        font-size: modular-scale(6);
        letter-spacing: .1rem;
        text-align: center;
        color: #fff;
        max-width: 548px;
        line-height: 2;
        background: #818181;
        @include margin(2.75rem auto null);
    }
}
.section {
    margin-top: 6.75rem;

    opacity: 0;
    &.on {
        animation: fadeIn .6s;
        opacity: 1;
    }
}
.heading {
    max-width: 450px;
    @include margin(null auto);
    h2 {
        font-weight: 700;
        font-size: modular-scale(8);
        letter-spacing: .2rem;
        text-align: center;
        border-bottom: 3px solid $site-color;
        padding-bottom: .75rem;
    }
    h3 {
        font-size: modular-scale(4);
        letter-spacing: .2rem;
        text-align: center;
        color: #818181;
        margin-bottom: 0;
    }
}
.content {
    @include padding(3.75rem null);
    dl {
        > div {
            display: flex;
            dt {
                flex-basis: 192px;
                flex-shrink: 0;
                @include padding(null .75rem);
            }
        }
        > div + div {
            margin-top: 1.75rem;
        }
    }
    &.is-small {
        max-width: 620px;
        @include margin(null auto);
    }
}

.display1,
.display2 {
    font-weight: bold;
    font-size: modular-scale(9);
    letter-spacing: 0.1em;
    text-align: center;
    
}

.display2 {
    color: #818181;
}

.image-caption-wrapper {

}

.image-caption-item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .image {
        flex-basis: 380px;
        img {
            max-width: 320px;
        }
    }
    .caption {
        font-size: modular-scale(2);
    }
}

.image-caption-item + .image-caption-item {
    margin-top: 1.5rem;
}

.page {
    .global-footer {
        margin-top: 6.75rem;
    }
}

.business {
    background: url('../img/02.jpg') no-repeat center bottom / 1600px auto;
    height: auto;
}


.recruit {
    background: url('../img/04.jpg') no-repeat center bottom / 1600px auto;
    height: auto;
}

.contact {
    background: url('../img/05.jpg') no-repeat center bottom / 1600px auto;
    height: auto;
}
