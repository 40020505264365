$font-stack-sans: (
    'Avenir',
    'Helvetica Neue',
    'Helvetica',
    'Arial',
    'Noto Sans JP',
    'Hiragino Sans',
    'ヒラギノ角ゴシック',
    'BIZ UDGothic',
    sans-serif
);

$font-stack-serif: (
    'Georgia',
    'ヒラギノ明朝 ProN',
    'Hiragino Mincho ProN',
    'BIZ UDMincho',
    serif
);

// Colors
$blue: #1565c0;
$blue-light: #3b83d5;
$gray: #333;
$white: #fff;

// Font Colors
$font-color--base: $gray;
// $action-color: $blue;
$action-color: inherit;
$action-color--alt: tint($gray, 30%);
$action-color--contrast: $white;

// Background Colors
$viewport-background-color: $white;

:root {
  // Typography
  // --font-family: system-ui, sans-serif;
  --font-family: #{$font-stack-sans};
  --font-family--heading: var(--font-family);

  --font-weight--thin: 100;
  --font-weight--extra-light: 200;
  --font-weight--light: 300;
  --font-weight--normal: 400;
  --font-weight--medium: 500;
  --font-weight--semi-bold: 600;
  --font-weight--bold: 700;
  --font-weight--extra-bold: 800;
  --font-weight--black: 900;

  // Line heights
  --line-height: 1.75;
  --line-height--heading: 1.25;

  // Other Sizes
  --border-radius: 0.1875rem;
  --spacing: 1.5rem;
  --spacing--small: 0.75rem;

  // Borders
  --border-width: 0.0625rem;
  --border: var(--border-width) solid currentColor;

  // Focus
  --focus-outline-width: 0.1875rem;
  --focus-outline-offset: 0.125rem;
  --focus-outline: var(--focus-outline-width) solid #{$action-color};

  // Animations
  --duration: 150ms;
  --timing: ease;
}
