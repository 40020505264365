:root {
  --form-box-shadow: inset 0 --border-width 0.1875rem rgba(#000, 0.06);
  --form-box-shadow-focus: var(--form-box-shadow), 0 0 0.3125rem #{$action-color};
}

fieldset {
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  font-weight: 600;
  margin-bottom: var(--spacing--small);
  padding: 0;
}

label {
  display: block;
  font-weight: 600;
  margin-bottom: var(--spacing--small);
}

input,
select,
textarea {
  display: block;
  font-family: var(--font-family);
  font-size: 1rem;
}

[type="color"],
[type="date"],
[type="datetime"],
[type="datetime-local"],
[type="email"],
[type="month"],
[type="number"],
[type="password"],
[type="search"],
[type="tel"],
[type="text"],
[type="time"],
[type="url"],
[type="week"],
input:not([type]),
textarea {
  appearance: none;
  background-color: transparent;
  border: var(--border);
  border-radius: var(--border-radius);
  box-shadow: var(--form-box-shadow);
  box-sizing: border-box;
  margin-bottom: var(--spacing--small);
  padding: calc(var(--spacing) / 3);
  transition: border-color var(--duration) var(--timing);
  width: 100%;

  &:focus {
    box-shadow: var(--form-box-shadow-focus);
  }

  &:disabled {
    cursor: not-allowed;

    &:hover {
      border: var(--border);
    }
  }

  &::placeholder {
    color: $font-color--base;
    opacity: 0.25;
  }
}

[type="search"] {
  -webkit-appearance: textfield;
}

textarea {
  resize: vertical;
}

[type="checkbox"],
[type="radio"] {
  display: inline;
  margin-right: var(--spacing--small);
}

[type="file"] {
  margin-bottom: var(--spacing--small);
  width: 100%;
}

select {
  margin-bottom: var(--spacing--small);
  width: 100%;
}

[type="checkbox"],
[type="radio"],
[type="file"],
select {
  &:focus {
    outline: var(--focus-outline);
    outline-offset: var(--focus-outline-offset);
  }
}
