table {
  border-collapse: collapse;
  margin: var(--spacing) 0;
  table-layout: fixed;
  text-align: left;
  width: 100%;
}

thead {
  line-height: var(--line-height--heading);
  vertical-align: bottom;
}

tbody {
  vertical-align: top;
}

tr {
  border-bottom: var(--border);
}

th {
  font-weight: 600;
}

th,
td {
  padding: var(--spacing--small) var(--spacing--small) var(--spacing--small) 0;
}
