ul,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

dl {
  margin: 0;
}

dt {
  // font-weight: 700;
  margin: 0;
}

dd {
  margin: 0;
}
